import React from 'react'
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'

const Showcase = class extends React.Component {
  render() {
    return (
      <section className="cShowcase">
        <div className="cShowcase-inner container">
          <div className="cShowcase-list">
          {this.props.showcases.map((showcase, idx) => (
            <div className="cShowcase-item" key={`${showcase.title}-${idx}`}>
              {showcase.images ?
                <Fade>
                  <div className="cShowcase-image-container">
                    {showcase.images.map((image, index) => (
                      <Img
                        fluid={image.childImageSharp.fluid}
                        alt={showcase.title}
                        key={`${showcase.title}-image-${index}`}
                        className="cShowcase-image"
                      />
                    ))}
                  </div>
                </Fade>
              : null}
              <Fade cascade>
                <div className="cShowcase-content">
                  <h3 className="cShowcase-title text">{ showcase.url ? (<a href={showcase.url} target="_blank" rel="noopener noreferrer" className="cShowcase-link link">{showcase.title}</a>) : showcase.title}</h3>
                  <h4 className="cShowcase-text text">{showcase.text}</h4>
                </div>
              </Fade>
            </div>
          ))}
          </div>
        </div>
      </section>
    )
  }
}

export default Showcase
