import React from 'react'
import Fade from 'react-reveal/Fade'

const Clients = class extends React.Component {
  render() {
    return (
      <section className="cClients">
        <div className="cClients-inner container">
          <Fade>
            <h2 className="cClients-title title" dangerouslySetInnerHTML={{ __html: this.props.title }}></h2>
          </Fade>
          <div className="cClients-columns">
          {this.props.lists.map((list, index) => (
            <div className="cClients-column" key={`${list.title}-${index}`}>
              <h3 className="cClients-heading text"><Fade>{list.title}</Fade></h3>
              <Fade cascade>
                <ul className="cClients-list">
                  {list.items.map((client, idx) => (
                    <li className="cClients-item" key={`${client.title}-${idx}`}>
                      <a href={client.url} target="_blank" rel="noopener noreferrer" className="cClients-link link text">{client.title}</a>
                    </li>
                  ))}
                </ul>
              </Fade>
            </div>
          ))}
          </div>
        </div>
      </section>
    )
  }
}

export default Clients
