import React from 'react'
import Fade from 'react-reveal/Fade'

const Text = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textIdx: 0,
      visible: true
    };
  }

  updateText(idx = null) {
    const newIndex = (idx === null ? this.state.textIdx + 1 : idx) % this.props.titles.length
    this.setState({ visible: false })
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.setState({
        textIdx: newIndex,
        visible: true
      })
    }, 750)
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.updateText()
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    let title = this.props.titles[this.state.textIdx % this.props.titles.length];
    return (
      <Fade>
        <section className="cText">
          <div className="cText-inner container">
            <h2 className={`cText-title title ${this.state.visible && 'is-visible' }`} dangerouslySetInnerHTML={{ __html: title }} />
            <h3 className="cText-subtitle text">{this.props.subtitle}</h3>
          </div>
        </section>
      </Fade>
    )
  }
}

export default Text
