import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Text from '../components/Text'
import About from '../components/About'
import Clients from '../components/Clients'
import Showcase from '../components/Showcase'

export const IndexPageTemplate = ({
  titles,
  subtitle,
  showcases,
  clients,
  about
}) => (
  <main className="cIndex">
    <Text titles={titles} subtitle={subtitle} />
    <Showcase showcases={showcases} />
    <Clients title={clients.title} lists={clients.lists} />
    <About title={about.title} text={about.text} />
  </main>
)

IndexPageTemplate.propTypes = {
  titles: PropTypes.array,
  subtitle: PropTypes.string,
  showcases: PropTypes.array,
  clients: PropTypes.shape({
    title: PropTypes.string,
    lists: PropTypes.array,
  }),
  about: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout location={location}>
      <Helmet titleTemplate="%s">
        <title>{`${frontmatter.title}`}</title>
        <meta
          name="description"
          content={`${frontmatter.description}`}
        />
        <meta property="og:title" content={frontmatter.title} />
      </Helmet>
      <IndexPageTemplate
        titles={frontmatter.titles}
        subtitle={frontmatter.subtitle}
        showcases={frontmatter.showcases}
        clients={frontmatter.clients}
        about={frontmatter.about}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        titles
        subtitle
        title
        description
        showcases {
          title
          text
          url
          images {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        clients {
          title
          lists {
            title
            items {
              title
              url
            }
          }
        }
        about {
          title
          text
        }
      }
    }
  }
`
